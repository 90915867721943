<template>
  <div>
    <div class="row branding-page">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('MENU.SETTINGS')">
          <template v-slot:body>
            <div class="d-flex flex-wrap">
              <div
                class="px-2 text-center setting-item"
                v-b-modal.chPassword-modal
              >
                <img src="@/assets/media/settings/change_password.jpg" />
                <p>{{ $t("SETTINGS.CHANGE_PASSWORD") }}</p>
              </div>
              <div
                class="px-2 text-center setting-item"
                v-b-modal.chLocation-modal
              >
                <img src="@/assets/media/settings/timezone.jpg" />
                <p>{{ $t("COMMON.LOCALIZATION") }}</p>
              </div>
              <div
                class="px-2 text-center setting-item"
                v-b-modal.chEmail-modal
              >
                <img src="@/assets/media/settings/change_email.jpg" />
                <p>{{ $t("SETTINGS.CHNAGE_EMAIL") }}</p>
              </div>
              <div
                class="px-2 text-center setting-item"
                v-b-modal.chSystext-modal
                v-if="hasPermission('subadmins', 9)"
              >
                <img src="@/assets/media/settings/system-texts.jpg" />
                <p>{{ $t("SETTINGS.SYSTEM_TEXTS") }}</p>
              </div>
              <div
                class="px-2 text-center setting-item"
                v-b-modal.chTimeout-modal
              >
                <v-icon
                  size="55"
                  color="#FAE264"
                  class="mr-2"
                >
                  mdi-av-timer
                </v-icon>
                <p>{{ $t("SETTINGS.TIMEOUT") }}</p>
              </div>
              <div
                class="px-2 text-center setting-item"
                v-b-modal.chHomeCountry-modal
              >
                <v-icon
                  size="55"
                  color="#FAE264"
                  class="mr-2"
                >
                  mdi-shield-home
                </v-icon>
                <p>{{ $t("COMMON.HOME_COUNTRY") }}</p>
              </div>
            </div>

            <!-- ----------- change password modal -->
            <b-modal
              id="chPassword-modal"
              ref="changePassword"
              :title="$t('SETTINGS.CHANGE_PASSWORD')"
            >
              <b-container fluid>
                <b-row class="my-3">
                  <b-col sm="5">
                    <label for="newPass">{{
                      $t("SETTINGS.NEW_PASSWORD")
                    }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="newPass"
                      v-model="$v.form.newPass.$model"
                      :state="validateState('newPass')"
                      :placeholder="$t('SETTINGS.NEW_PASSWORD')"
                      aria-describedby="input-new-pass-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-new-pass-feedback">{{
                      $t("VALIDATION.REQUIRED_MINLENGTH_FIELD", { num: "3" })
                    }}</b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row class="my-3">
                  <b-col sm="5">
                    <label for="confirmPass">{{
                      $t("SETTINGS.CONFIRM_PASSWORD")
                    }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="confirmPass"
                      v-model="$v.form.confPass.$model"
                      :state="validateState('confPass')"
                      :placeholder="$t('SETTINGS.CONFIRM_PASSWORD')"
                      aria-describedby="input-conf-pass-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-conf-pass-feedback">{{
                      $t("VALIDATION.IDENTICAL_FIELD")
                    }}</b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="modalClose('changePassword')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="primary" v-on:click="changePassword()">
                  {{ $t("SETTINGS.CHANGE_PASSWORD") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ---------- change password modal end------------- -->
            <!-- ----------- change locale alert modal -->
            <b-modal
              id="chLocation-modal"
              ref="chLocation"
              :title="$t('SETTINGS.CHANGE_ADMIN_LOCALE')"
            >
              <b-container fluid>
                <b-row class="my-3">
                  <b-col sm="5">
                    <label>{{ $t("COMMON.TIMEZONE") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="form.timeZone.selected"
                      value-field="timezone"
                      text-field="timezone"
                      :options="form.timeZone.data"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-3">
                  <b-col sm="5">
                    <label>{{ $t("COMMON.DATE_FORMAT") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="form.dateFormat.selected"
                      value-field="value"
                      text-field="value"
                      :options="form.dateFormat.data"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="modalClose('chLocation')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="primary" v-on:click="changeLocation()">
                  {{ $t("SETTINGS.CHANGE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ---------- change quota alert modal end------------- -->
            <!-- ----------- change email modal -->
            <b-modal
              id="chEmail-modal"
              ref="changeEmail"
              :title="$t('SETTINGS.CHNAGE_EMAIL')"
            >
              <b-container fluid>
                <b-row class="my-3">
                  <b-col sm="5">
                    <label>{{ $t("SETTINGS.CURRENT_EMAIL") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      v-model="form.currentEmail"
                      readonly
                    ></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="my-3">
                  <b-col sm="5">
                    <label for="newEmail">{{ $t("SETTINGS.NEW_EMAIL") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="newEmail"
                      v-model="$v.form.newEmail.$model"
                      :state="validateState('newEmail')"
                      :placeholder="$t('SETTINGS.NEW_EMAIL')"
                      aria-describedby="input-new-pass-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-new-pass-feedback">
                      {{ $t("VALIDATION.EMAIL_FIELD") }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="modalClose('changeEmail')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="primary" v-on:click="changeEmail()">
                  {{ $t("SETTINGS.CHANGE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ---------- change email modal end------------- -->
            <!-- ----------- change system text modal -->
            <b-modal
              id="chSystext-modal"
              ref="chSystext"
              size="lg"
              :title="$t('SETTINGS.CHANGE_SYSTEXT')"
            >
              <b-container fluid>
                <b-row class="my-3">
                  <b-col sm="3">
                    <label>{{ $t("SETTINGS.TEXT_FOR") }}</label>
                  </b-col>
                  <b-col sm="9">
                    <b-form-select
                      v-model="form.textFor.selected"
                      value-field="value"
                      text-field="name"
                      :options="textForData"
                      @change="changeSysText()"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-3">
                  <b-col sm="3">
                    <label for="sysTextContent">{{
                      $t("SETTINGS.TEXT")
                    }}</label>
                  </b-col>
                  <b-col sm="9">
                    <b-form-textarea
                      id="sysTextContent"
                      v-model="$v.form.sysTextContent.$model"
                      :placeholder="$t('COMMON.DESCRIPTION')"
                      rows="8"
                    ></b-form-textarea>
                  </b-col>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="modalClose('chSystext')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="primary" v-on:click="updateSysText()">
                  {{ $t("SETTINGS.CHANGE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ---------- change system text modal end------------- -->
            <!-- ----------- change timeout modal -->
            <b-modal
              id="chTimeout-modal"
              ref="changeTimeout"
              :title="$t('SETTINGS.TIMEOUT')"
            >
              <b-container fluid>
                <b-row class="my-3">
                  <b-col sm="5">
                    <label>{{ $t("SETTINGS.MINUTES") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="form.timeout.selected"
                      value-field="value"
                      text-field="name"
                      :options="timeoutData"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="modalClose('changeTimeout')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="primary" v-on:click="changeTimeout()">
                  {{ $t("SETTINGS.CHANGE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ---------- change timeout modal end------------- -->
            <!-- ----------- change HOME COUNTRY modal -->
            <b-modal
              id="chHomeCountry-modal"
              ref="changeHomecountry"
              :title="$t('COMMON.HOME_COUNTRY')"
            >
              <b-container fluid>
                <b-row class="my-3">
                  <b-col sm="5">
                    <label>{{ $t("COMMON.HOME_COUNTRY") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="form.homeCountry.selected"
                      value-field="value"
                      text-field="text"
                      :options="form.homeCountry.data"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="modalClose('changeHomecountry')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="primary" v-on:click="changeHomecountry()">
                  {{ $t("SETTINGS.CHANGE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ---------- change home country modal end------------- -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs, email } from "vuelidate/lib/validators";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";
import { TIMEZONES, DATEFORMATS } from "@/store/basedata.module";

export default {
  mixins: [validationMixin],
  name: "branding",
  components: {
    KTPortlet,
  },
  data: () => ({
    form: {
      newPass: null,
      confPass: null,
      quotaAlert: {
        selected: 0,
      },
      newEmail: "",
      currentEmail: "",
      dateFormat: {
        selected: "",
        data: [],
      },
      timeZone: {
        selected: "",
        data: [],
      },
      homeCountry: {
        selected: "",
        data: [],
      },
      textFor: {
        selected: "forgot_password_mail_text",
      },
      sysTextContent: "",
      sysTextId: "",
      sysTextData: [],
      timeout: {
        selected: ""
      }
    },
  }),
  computed: {
    textForData: function () {
      return [
        {
          name: this.$t("SETTINGS.FORGOT_PASSWORD_MAIL_TEXT"),
          value: "forgot_password_mail_text",
        },
        {
          name: this.$t("SETTINGS.FORGOT_PASSWORD_WEB_TEXT"),
          value: "forgot_password_web_text",
        },
        {
          name: this.$t("SETTINGS.QUOTA_WARNING_TEXT"),
          value: "quota_warning_text",
        },
        {
          name: this.$t("SETTINGS.FORGOT_PASSWORD_MAIL_SUBJECT"),
          value: "forgot_password_mail_subject",
        },
        {
          name: this.$t("SETTINGS.QUOTA_WARNING_SUBJECT"),
          value: "quota_warning_subject",
        },
        {
          name: this.$t("SETTINGS.PASSWORD_EXPIRY_WARNING_TEXT"),
          value: "password_expiry_warning_text",
        },
        {
          name: this.$t("SETTINGS.PASSWORD_EXPIRY_WARNING_SUBJECT"),
          value: "password_expiry_warning_subject",
        },
        {
          name: this.$t("SETTINGS.PASSWORD_EXPIRED_WARNING_TEXT"),
          value: "password_expired_warning_text",
        },
        {
          name: this.$t("SETTINGS.PASSWORD_EXPIRED_WARNING_SUBJECT"),
          value: "password_expired_warning_subject",
        },
      ];
    },
    quotaAlertData: function () {
      return [
        { name: this.$t("COMMON.YES"), value: 1 },
        { name: this.$t("COMMON.NO"), value: 0 },
      ];
    },
    timeoutData: function () {
      return [
        { name: "1", value: "60000" },
        { name: "5", value: "300000" },
        { name: "15", value: "900000" },
        { name: "30", value: "1800000" },
        { name: "60", value: "3600000" },
      ];
    },
  },
  created() {
    /*  get timezone & timeformat data */
    let stateTimezones = this.$store.state.basedata.timezones;
    let stateDateformats = this.$store.state.basedata.dateformats;
    if (Object.keys(stateTimezones).length == 0) {
      this.$store
        .dispatch(TIMEZONES, {
          action: "getTimezones",
          token: localStorage.id_token,
        })
        .then((res) => {
          if (res.returncode) {
            this.form.timeZone.data = res.returndata;
            // this.form.timeZone.selected = res.returndata[0].timezone;
            if (Object.keys(stateDateformats).length == 0) {
              this.$store
                .dispatch(DATEFORMATS, {
                  action: "getDateFormats",
                  token: localStorage.id_token,
                })
                .then((res) => {
                  if (res.returncode) {
                    let updatedReturnData = [];
                    let i;
                    for (i in res.returndata) {
                      updatedReturnData.push({ value: res.returndata[i] });
                    }
                    this.form.dateFormat.data = updatedReturnData;
                    // this.form.dateFormat.selected = updatedReturnData[0].value;
                  }
                });
            }
          }
        });
    } else {
      this.form.timeZone.data = stateTimezones;
      // this.form.timeZone.selected = stateTimezones[0].timezone;
      this.form.dateFormat.data = stateDateformats;
      // this.form.dateFormat.selected = stateDateformats[0].value;
    }
    /* set initial timezone */
    postRequest({
      action: "getAdminTimezone",
      token: localStorage.id_token,
    }).then((res) => {
      if (res) {
        this.form.timeZone.selected = res;
      } else {
        this.makeToastVariant("danger", res);
      }
    });

    /* set initial dateformat */
    postRequest({
      action: "getAdminDateFormat",
      token: localStorage.id_token,
    }).then((res) => {
      if (res && res.returncode) {
        this.form.dateFormat.selected = res.returndata.dateformat;
      } else {
        this.makeToastVariant("danger", res.returndata);
      }
    });

    /* set current email and home country*/
    postRequest({
      action: "getAdminInfo",
      token: localStorage.id_token,
    }).then((res) => {
      if (res && res.returncode) {
        this.form.currentEmail = res.returndata.emailadmin;
        this.form.homeCountry.selected = res.returndata.homecountry ? res.returndata.homecountry:"UN";
      } else {
        this.makeToastVariant("danger", res.returndata);
      }
    });

    postRequest({
      action: "getCountries",
      token: localStorage.id_token,
    }).then((res) => {
      if (res) {
        if (!res.returncode) {
          res.returndata = [{ text: res.returndata }];
        } else {
          for (var key in res.returndata) {
            this.form.homeCountry.data.push({
              value: key,
              text: res.returndata[key],
            });
          }
        }
      }
    });

    /* set system text */
    if (this.hasPermission("subadmins", 9)) {
      this.getSysTextData();
    }

    /* set timeout */
    this.form.timeout.selected = localStorage.timeout;
  },
  validations: {
    form: {
      newPass: {
        required,
        minLength: minLength(3),
      },
      confPass: {
        required,
        sameAsPassword: sameAs("newPass"),
      },
      sysTextContent: {
        required,
      },
      newEmail: {
        required,
        email,
      },
      timeZone: {
        selected: "",
        data: [],
      },
      dateFormat: {
        selected: "",
        data: [],
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.SETTINGS") }]);
  },
  methods: {
    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    modalClose(item) {
      this.form.newPass = null;
      this.form.confPass = null;
      this.form.newEmail = null;

      const initTextFor = this.form.sysTextData["forgot_password_mail_text"];
      if (initTextFor != null) {
        this.form.sysTextId = initTextFor.id;
        this.form.sysTextContent = initTextFor.value;
      } else {
        this.form.sysTextId = "";
        this.form.sysTextContent = "";
      }
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.$refs[item].hide();
    },
    getSysTextData() {
      postRequest({
        action: "getAdminSettings",
        token: localStorage.id_token,
      }).then((res) => {
        if (res && res.returncode) {
          this.form.sysTextData = res.returndata;
          const initTextFor = res.returndata["forgot_password_mail_text"];
          if (initTextFor != null) {
            this.form.sysTextId = initTextFor.id;
            this.form.sysTextContent = initTextFor.value;
          }
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    /* ---------- update setting ---------- */
    changePassword() {
      this.$v.form.$touch();
      if (this.$v.form.newPass.$anyError || this.$v.form.confPass.$anyError) {
        return;
      }
      postRequest({
        action: "changePassword",
        token: localStorage.id_token,
        password1: this.form.newPass,
        password2: this.form.confPass,
      }).then((res) => {
        if (res && res.returncode) {
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.modalClose("changePassword");
      });
    },
    changeLocation() {
      postRequest({
        action: "setAdminTimezone",
        token: localStorage.id_token,
        timezone: this.form.timeZone.selected,
        dateformat: this.form.dateFormat.selected,
      }).then((res) => {
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.modalClose("chLocation");
      });
    },
    changeEmail() {
      this.$v.form.$touch();
      if (this.$v.form.newEmail.$anyError) {
        return;
      }
      postRequest({
        action: "changeEmail",
        token: localStorage.id_token,
        emailadmin: this.form.newEmail,
      }).then((res) => {
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.modalClose("changeEmail");
      });
    },
    changeTimeout() {
      this.$v.form.$touch();
      /*if (this.$v.form.timeout.$anyError) {
        return;
      }*/
      postRequest({
        action: "setUiTimeout",
        token: localStorage.id_token,
        ui_timeout: this.form.timeout.selected,
      }).then((res) => {
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
          localStorage.timeout = this.form.timeout.selected;
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.modalClose("changeTimeout");
      });
    },
    changeHomecountry() {
      this.$v.form.$touch();
      postRequest({
        action: "setAdminHomecountry",
        token: localStorage.id_token,
        homecountry: this.form.homeCountry.selected,
      }).then((res) => {
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.modalClose("changeHomecountry");
      });
    },
    changeSysText() {
      const textFor = this.form.textFor.selected;
      const textData = this.form.sysTextData[textFor];
      if (textData != null) {
        this.form.sysTextId = textData.id;
        this.form.sysTextContent = textData.value;
      } else {
        this.form.sysTextId = "";
        this.form.sysTextContent = "";
      }
    },
    updateSysText() {
      this.$v.form.$touch();
      if (this.$v.form.sysTextContent.$anyError) {
        return;
      }
      postRequest({
        systemTextsOptions: this.form.sysTextId,
        action: "changePreference",
        token: localStorage.id_token,
        systemtexts: this.form.sysTextContent,
        label: this.form.textFor.selected,
      }).then((res) => {
        if (res && res.returncode) {
          this.makeToastVariant("success", res.returndata);
          this.getSysTextData();
          this.modalClose("chSystext");
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
  },
};
</script>
